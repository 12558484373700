import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDOcaDRn8A_iyqeiQ6tT74BBUJonplo3tw",
  authDomain: "photo-gallery-d5a11.firebaseapp.com",
  projectId: "photo-gallery-d5a11",
  storageBucket: "photo-gallery-d5a11.appspot.com",
  messagingSenderId: "457066640886",
  appId: "1:457066640886:web:64367a6c8202ba95f443d7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };